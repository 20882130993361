.container{
    width: 100%;
    height: 100vh;
    padding-top: 50px;
    background-color: rgb(116, 152, 225);
}

.weather{
    width: 380px;
    padding: 30px;
    border-radius: 10px;
    margin: 50px auto;
    text-align: center;
    color: white;
    background: linear-gradient(130deg, rgb(86, 86, 235), rgb(1, 1, 50));
}

.search {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.search input{
    border: none;
    outline: 0;
    padding: 12px 25px;
    font-size: 18px;
    background-color: rgb(222, 237, 240);
    color: black;
    border-radius: 30px;
    flex: 1;
    margin-right: 12px;
}

.search button {
    border: none;
    outline: 0;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
}

.search button img{
    width: 20px;
    background-color:  rgb(222, 237, 240);;
}

.winfo .icon{
    margin-top: 20px;
    width: 120px;
}

.winfo h1{
    font-size: 40px;
    font-weight: 400;
    margin-top: 7px;
}

.winfo h2{
    font-size: 35px;
    font-weight: 400;
    margin-top: 20px;
}

.details{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
    margin-top: 50px;
}
.details .col{
    display: flex;
    align-items: center;
    text-align: left;
}
.details .col img{
    filter: invert(100%) sepia(3%) saturate(13%) hue-rotate(81deg) brightness(106%) contrast(106%);
    margin-right: 10px;
    width: 65px;
}
.humidity, .wind{
    font-size: 20px;
}
.humidity p, .wind p{
    margin: 0px;
}

.error{
    color: rgb(235, 169, 169);
    text-align: left;
    margin-left: 10px;
}